<template>
  <v-navigation-drawer
    :value="isAddResActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="val => $emit('update:is-add-res-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New Restaurant</span>
        <v-spacer></v-spacer>
        <v-btn icon small @click="$emit('update:is-add-res-active', false)">
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form ref="form" v-model="valid" @submit.prevent="onSubmit">
          <v-text-field
            v-model="ResData.Name"
            outlined
            dense
            :rules="[validators.required]"
            label="Name"
            placeholder="Full Name"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="ResData.Email"
            :rules="[validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <v-text-field
            v-model="ResData.PhoneNumber"
            :rules="[validators.required, validators.lengthValidator(ResData.PhoneNumber, 10)]"
            outlined
            dense
            type="number"
            label="Phone Number"
            placeholder="Phone Number"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            v-model="ResData.Type"
            outlined
            dense
            label="Type"
            placeholder="Type"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-select
            v-model="ResData.Status"
            :rules="[validators.required]"
            label="Status"
            :items="$store.state.status['restaurants']"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details
            class="mb-6"
          ></v-select>
          <v-text-field
            v-model="ResData.Description"
            outlined
            dense
            label="Description"
            placeholder="Description"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>
          <v-text-field
            hide-details="auto"
            v-model="ResData.Tax"
            type="number"
            readonly
            label="Tax"
            small
            :prepend-inner-icon="icons.mdiMinus"
            :append-icon="mdiPlus"
            @click:append="mpplus()"
            @click:prepend-inner="mpminus()"
          ></v-text-field>
          <br />
          <v-btn color="primary" class="me-3" type="submit"> Add </v-btn>
          <v-btn color="secondary" outlined type="reset" @click="resetResData"> Cancel </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { Create } from '@core/api/Restaurant'
import { emailValidator, lengthValidator, required } from '@core/utils/validation'
import { mdiClose, mdiMinus, mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'

export default {
  model: {
    prop: 'isAddResActive',
    event: 'update:is-add-res-active',
  },
  props: {
    isAddResActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const blankResData = {
      Id: undefined,
      Name: '',
      Description: '',
      Email: '',
      PhoneNumber: '',
      Type: '',
      Link: '',
      Status: '',
      Tax: 0.0,
    }
    const valid = ref(false)
    const form = ref(null)
    const validate = () => {
      form.value.validate()
    }
    const resetForm = () => {
      form.value.reset()
    }
    const ResData = ref(JSON.parse(JSON.stringify(blankResData)))
    const resetResData = () => {
      ResData.value = JSON.parse(JSON.stringify(blankResData))
      resetForm()
      emit('update:is-add-res-active', false)
    }
    const onSubmit = () => {
      if (valid.value) {
        ResData.value.Tax += 1
        Create(ResData.value).then(() => {
          emit('refetch-data')
          emit('update:is-add-res-active', false)
        })
        resetResData()
      } else {
        validate()
      }
    }
    const mpplus = () => {
      console.log('Tax plus', ResData.value.Tax)
      if (ResData.value.Tax < 0.99 ){
        let tax = ResData.value.Tax + 0.01
        ResData.value.Tax = parseFloat(tax.toFixed(2))
      }
    }
    const mpminus = () => {
      console.log('Tax minus', ResData.value.Tax)

      if (ResData.value.Tax > 0){
        let tax = ResData.value.Tax - 0.01
        ResData.value.Tax = parseFloat(tax.toFixed(2))
      }
    }
    return {
      mpminus,
      mpplus,
      resetResData,
      form,
      onSubmit,
      ResData,
      valid,
      validate,
      mdiPlus,

      // validation
      validators: { required, emailValidator, lengthValidator },
      icons: {
        mdiClose,
        mdiMinus,
      },
    }
  },
}
</script>
