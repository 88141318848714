<template>
  <v-row>
    <v-col cols="12">
      <v-row>
        <v-col cols="3">
          <v-btn right color="primary" class="mb-4 me-3" @click.ActiveR="isAddResActive = !isAddResActive">
            <v-icon size="18" class="me-1">
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Restaurant</span>
          </v-btn>
        </v-col>
        <v-col cols="9">
        </v-col>
      </v-row>
      <restaurant-add-new v-model="isAddResActive" @refetch-data="fetchRestaurants"></restaurant-add-new>
      <v-divider></v-divider>
      <br />
      <v-row>
        <v-col v-for="option in restaurantList" :key="option.Id" sm="12" md="8" lg="4" class="mx-auto">
          <res-com :id="option.Id" :item="option" :name="option.Name" @refetch-data="fetchRestaurants" :Link="option.Link"></res-com>
        </v-col>
      </v-row> </v-col
  ></v-row>
</template>
<script>
import { GetRestaurants } from '@core/api/Restaurant'
import { mdiPlus } from '@mdi/js'
import { ref } from '@vue/composition-api'
import ResCom from './ResCom.vue'
import RestaurantAddNew from './RestaurantAddNew.vue'

export default {
  components: {
    RestaurantAddNew,
    ResCom,
  },
  setup() {
    const isAddResActive = ref(false)
    const restaurantList = ref([])
    const fetchRestaurants = () => {
      restaurantList.value = []
      GetRestaurants().then(response => {
        restaurantList.value = response
      })
    }

    return {
      restaurantList,
      fetchRestaurants,
      isAddResActive,
      icons: {
        mdiPlus,
      },
    }
  },
  created() {
    this.fetchRestaurants()
  },
}
</script>
<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
